import { Box, Button, Slider, Typography } from '@mui/material'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { clientApi } from 'api/clientApi'
import { setAppFormUserFlow } from 'redux/reducers/auth/authReducer'
import { currentUserSelector, userFlowSelector } from 'redux/reducers/auth/authSelectors'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import { setClientToastMessage } from 'redux/reducers/client/allState/clientReducer'
import { appFormCreditPolicyData, appFormShopRequestDataSelector } from 'redux/reducers/client/allState/clientSelectors'
import { deleteUserFlow } from 'redux/reducers/client/allState/clientThunks'
import { confirmModalActions } from 'redux/reducers/common/confirmModal/confirmModalSlice'
import { getErrorMessage } from 'utils/getErrorMessage'
import { roundNumber } from 'utils/roundValues'

const AgreeNewLoanTermPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [loanAmount, setLoanAmount] = useState<number>(0)
  const [type, setType] = useState<number[]>([])
  const [newInterest, setNewInterest] = useState<number>(0)

  const { appFormFlowData } = useSelector(userFlowSelector)
  const currentUser = useSelector(currentUserSelector)
  const shopRequestData = useSelector(appFormShopRequestDataSelector)
  const creditPolicy = useSelector(appFormCreditPolicyData)

  const { handleSubmit } = useForm({ mode: 'onChange' })

  const handleCancelApplication = () => {
    dispatch(confirmModalActions.open({
      text: t('client.applicationForm.cancelApplicationConfirmation'),
      confirmAction: () => {
        if (appFormFlowData) {
          dispatch(deleteUserFlow(appFormFlowData.id))
          clientApi.applicationForm.changeStatus(appFormFlowData.flow_data.id, 10)
          dispatch(setAppFormUserFlow(null))
          history.push('/client/active-loans')
        } else {
          dispatch(setClientToastMessage({ type: 'error', message: 'Cannot cancel application' }))
        }
      }
    }))
  }

  const handleNext = useCallback(() => {
    if (appFormFlowData) {
      dispatch(updateAppFormUserFlowThunk({ step: appFormFlowData.flow_data.step + 1 }))
    }
  }, [appFormFlowData, dispatch])

  useEffect(() => {
    if (shopRequestData?.application?.loan_amount) {
      setLoanAmount(shopRequestData?.application?.loan_amount)
    }

    if (shopRequestData?.application?.risk_segment) {
      if (creditPolicy) {
        const fixedInterest = roundNumber(creditPolicy.interest * +shopRequestData?.application?.risk_segment?.multiply_interest)
        if (fixedInterest) {
          setNewInterest(+fixedInterest)
        }
      }

      if (+shopRequestData?.application?.risk_segment?.multiply_interest !== 1) {
        setType(prev => [...prev, 1])
      }

      if (+shopRequestData?.application?.risk_segment?.multiply_amount > 1) {
        setType(prev => [...prev, 2])
      }

      if (+shopRequestData?.application?.risk_segment?.multiply_amount < 1) {
        setType(prev => [...prev, 3])
        setLoanAmount(shopRequestData?.application?.loan_amount * +shopRequestData?.application?.risk_segment?.multiply_amount)
      }
    }
  }, [shopRequestData, creditPolicy])

  if (!appFormFlowData || !creditPolicy || !currentUser) return <Typography>{t('client.applicationForm.bankCard.noData')}</Typography>

  const {
    flow_data: { id: appId, step }
  } = appFormFlowData

  const onSubmit = async () => {
    try {
      const res = await clientApi.applicationForm
        .postApplicationByIdAgreeNewTerm(appId, { loan_amount: loanAmount, interest_rate: +newInterest.toFixed(4) })
      if (res) {
        dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))
      } else {
        dispatch(setClientToastMessage({ type: 'error', message: 'Some error' }))
      }
    } catch (err: any) {
      dispatch(
        setClientToastMessage({ type: 'error', message: getErrorMessage(err.responce.data) })
      )
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        mb={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {/* 1 */}
        {type.includes(1) && (
          <Typography data-tester-type={1}>
            {t('client.applicationForm.agreeNewLoanTermPage.annualInterestRateLoanReplacedByNewOne', { n: `${creditPolicy.interest}%` })} <b>{newInterest}%</b>
          </Typography>
        )}

        {/* 2 */}
         {shopRequestData?.application && shopRequestData?.application?.risk_segment && type.includes(2) && (
          <Box data-tester-type={2}>
            <Typography>{t('client.applicationForm.agreeNewLoanTermPage.increaseLoanAmountLeaveUnchanged')}</Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              '@media screen and (max-width: 675px)': {
                flexDirection: 'column',
                alignItems: 'normal'
              }
            }}>
              <Box sx={{
                minWidth: '400px',
                mb: 1,
                '@media screen and (max-width: 675px)': {
                  minWidth: 'auto'
                }
              }}>
                <Slider
                  data-tester-slider={'slider type 2'}
                  defaultValue={shopRequestData?.application?.loan_amount}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  min={shopRequestData?.application?.loan_amount}
                  max={shopRequestData?.application?.loan_amount * +shopRequestData?.application?.risk_segment?.multiply_amount}
                  step={100}
                  onChange={(e) => {
                    // @ts-ignore
                    if (e.target?.value) {
                      // @ts-ignore
                      setLoanAmount(e.target.value)
                    }
                  }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                  <Typography data-tester-min-amount={shopRequestData?.application?.loan_amount} fontWeight="bold">
                    {shopRequestData?.application?.loan_amount}
                  </Typography>
                  <Typography data-tester-max-amount={shopRequestData?.application?.loan_amount * +shopRequestData?.application?.risk_segment?.multiply_amount} fontWeight="bold">
                    {shopRequestData?.application?.loan_amount * +shopRequestData?.application?.risk_segment?.multiply_amount}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 2 }} data-tester-new-amount={loanAmount}>
                {t('client.applicationForm.agreeNewLoanTermPage.newLoanAmount')}
                <b>{loanAmount}</b>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, mb: 1 }} data-tester-old-amount={shopRequestData?.application?.loan_amount}>
              {t('client.applicationForm.agreeNewLoanTermPage.oldLoanAmount')}
              <b>{shopRequestData?.application?.loan_amount}</b>
            </Box>
          </Box>
         )}

        {/* 3 */}
        {shopRequestData?.application && shopRequestData?.application?.risk_segment && type.includes(3) && (
          <Box sx={{ display: 'flex', gap: 2, mt: 1 }} data-tester-type={3}>
            {t('client.applicationForm.agreeNewLoanTermPage.weCanOfferYouFollowingLoanAmount')}
            <b>{shopRequestData?.application?.loan_amount * +shopRequestData?.application?.risk_segment?.multiply_amount}</b>
          </Box>
        )}
      </Box>

      <Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          mb: 1
        }}>
          <Button onClick={(type.includes(1) || type.includes(3)) ? handleCancelApplication : handleNext} variant="contained">
            {t('common.buttons.reject')}
          </Button>
          <Button type="submit" variant="contained">
            {t('common.buttons.toAgree')}
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default AgreeNewLoanTermPage
