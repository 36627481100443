import { Box, Button, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { clientApi } from 'api/clientApi'
import { useSearchParams } from 'components/common/hooks/useSearchParams'
import { userFlowSelector } from 'redux/reducers/auth/authSelectors'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import { setCardVerifiedStatus, setClientToastMessage } from 'redux/reducers/client/allState/clientReducer'
import { appFormShopRequestDataSelector, cardVerificationSelector } from 'redux/reducers/client/allState/clientSelectors'
import { checkCardVerificationStatusThunk } from 'redux/reducers/client/allState/clientThunks'
import { IPlatonAuth } from 'types/client/ApplicationForm'
import { getErrorMessage } from 'utils/getErrorMessage'

const CardVerificationBlock = () => {
  const { isLoading, verifiedStatus } = useSelector(cardVerificationSelector)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const params = useSearchParams()

  const [requestData, setRequestData] = useState<IPlatonAuth | undefined>()
  const [toggleSubmit, setToggleSubmit] = useState(false)

  const { appFormFlowData } = useSelector(userFlowSelector)
  const shopRequestData = useSelector(appFormShopRequestDataSelector)

  const buttonRef = useRef() as React.MutableRefObject<HTMLButtonElement>

  const orderId = params('order')

  const getCardVerification = async () => {
    try {
      if (appFormFlowData?.flow_data?.id) {
        const res = await clientApi.applicationForm.postPlatonPaymentAuth(appFormFlowData?.flow_data?.id)
        if (res.status === 200) {
          setRequestData(res.data)
          setToggleSubmit(!toggleSubmit)
        }
      }
    } catch (err: any) {
      dispatch(setClientToastMessage({ type: 'error', message: getErrorMessage(err.responce.data) }))
    }
  }

  // check status card
  useEffect(() => {
    if (verifiedStatus === 'done') return

    const currentOrderId = shopRequestData?.order_id
      ? shopRequestData?.order_id
      : orderId
    if (currentOrderId) {
      dispatch(checkCardVerificationStatusThunk(currentOrderId))

      const interval = setInterval(() => dispatch(checkCardVerificationStatusThunk(currentOrderId)), 10000)

      return () => clearInterval(interval)
    } else {
      dispatch(setCardVerifiedStatus('not-performed'))
    }
  }, [dispatch, verifiedStatus, orderId, shopRequestData])

  // if requestData send data to Platon
  useEffect(() => {
    if (buttonRef.current && requestData) buttonRef.current.click()
  }, [toggleSubmit, requestData])

  // if verifiedStatus === 'done' go to next step application
  useEffect(() => {
    if (appFormFlowData && verifiedStatus === 'done') {
      dispatch(updateAppFormUserFlowThunk({ step: appFormFlowData.flow_data.step + 1 }))
    }
  }, [dispatch, appFormFlowData, verifiedStatus])

  return <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">

    {isLoading && <>
      <CircularProgress />
      <Typography mt={2}>{t('client.applicationForm.bankCard.verificationCheckPleaseWait')}</Typography>
    </>}

    {verifiedStatus === 'not-performed' && (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
        <Button
          disabled={isLoading}
          variant='contained'
          onClick={getCardVerification}
        >
          {t('client.applicationForm.bankCard.performVerification')}
        </Button>
      </Box>
    )}

    {!isLoading && verifiedStatus === 'in-process' && (
      <Typography mt={2} fontWeight="bold">
        {t('client.applicationForm.bankCard.checkVerified')}
      </Typography>
    )}

    {!isLoading && verifiedStatus && ['created', 'running'].includes(verifiedStatus) && (
      <Typography fontWeight="bold">
        {t('client.applicationForm.bankCard.verificationInProgress')}
      </Typography>
    )}

    {!isLoading && verifiedStatus === 'failed' && (
      <Typography fontWeight="bold">
        {t('client.applicationForm.bankCard.verificationFailed')}
      </Typography>
    )}

    {requestData && (
      <form action="https://secure.platononline.com/payment/auth" method="post">
        <input type="hidden" name="key" value={requestData.key} />
        <input type="hidden" name="payment" value={requestData.payment} />
        <input type="hidden" name="data" value={requestData.data} />
        <input type="hidden" name="url" value={requestData.url} />
        <input type="hidden" name="formid" value={requestData.formid} />
        <input type="hidden" name="email" value={requestData.email} />
        <input type="hidden" name="phone" value={requestData.phone} />
        <input type="hidden" name="first_name" value={requestData.first_name} />
        <input type="hidden" name="last_name" value={requestData.last_name} />
        <input type="hidden" name="zip" value={requestData.zip} />
        <input type="hidden" name="country" value={requestData.country} />
        <input type="hidden" name="reg_token" value={requestData.reg_token} />
        <input type="hidden" name="order" value={requestData.order} />
        <input type="hidden" name="sign" value={requestData.sign} />
        {
          <button type="submit" className="d-none" ref={buttonRef}>
            {t('common.buttons.payment')}
          </button>
        }
      </form>
    )}
  </Box>
}

export default CardVerificationBlock
