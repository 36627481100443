import {
  Box,
  Button,
  TextField,
  Typography
} from '@mui/material'
import React, { FC, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { clientApi } from 'api/clientApi'
import FormErrorMessage from 'components/common/formErrorMessage/formErrorMessage'
import AppPreloader from 'components/common/preloaders/appPreloader'
import { COUNTRY } from 'config'
import { setAppFormUserFlow } from 'redux/reducers/auth/authReducer'
import { cardStepSettingsSelector, currentUserSelector, userFlowSelector } from 'redux/reducers/auth/authSelectors'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import { setClientIsLoading, setClientToastMessage } from 'redux/reducers/client/allState/clientReducer'
import { appFormCreditPolicyData, cardVerificationSelector, clientIsLoadingSelector } from 'redux/reducers/client/allState/clientSelectors'
import { applicationRiskScoreRequest, deleteUserFlow } from 'redux/reducers/client/allState/clientThunks'
import { getErrorMessage } from 'utils/getErrorMessage'

import CardVerificationBlock from './CardVerificationBlock'

const BankCardForm: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const { appFormFlowData } = useSelector(userFlowSelector)
  const currentUser = useSelector(currentUserSelector)
  const creditPolicy = useSelector(appFormCreditPolicyData)
  const settings = useSelector(cardStepSettingsSelector)
  const isLoading = useSelector(clientIsLoadingSelector)

  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' })

  const handlePrev = useCallback(() => {
    if (appFormFlowData) {
      dispatch(updateAppFormUserFlowThunk({ step: appFormFlowData.flow_data.step - 1 }))
    }
  }, [appFormFlowData, dispatch])

  const getCurrentStatusId = useCallback(async (stepNext: boolean = true) => {
    try {
      dispatch(setClientIsLoading(true))
      if (creditPolicy) {
        if (settings?.active && stepNext) {
          const appResUpd = await clientApi.applications.getApplication(appId)

          if (appResUpd.status === 200) {
            const appUpdStatusId = appResUpd.data.status.id

            if (appUpdStatusId === 11) {
              // console.log(appUpdStatusId)
            }

            // Андеррайтинг
            if (appUpdStatusId === 2 && stepNext) {
              const isFlowUpdate = await dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))
              // @ts-ignore
              if (isFlowUpdate) history.replace('/client/active-loans')
            }

            // Підписання договору
            if (((appUpdStatusId === 4) || (appUpdStatusId === 14)) && stepNext) {
              dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))
            }
          }
          return
        }
        // 2 step - отримуємо поточний status.id заявки
        const appRes = await clientApi.applications.getApplication(appId)

        if (appRes.status === 200) {
          const appCurrentStatusId = appRes.data.status.id
          const statusIdToUpdate = 4

          // if (appCurrentStatusId !== 2 && creditPolicy?.manual_verification) {
          //   statusIdToUpdate = 2
          // }

          // на який статус патчити якщо тільки гарантор?
          if (creditPolicy?.is_guarantor_required) {
            //
          }
          await clientApi.applicationForm.changeStatus(appId, statusIdToUpdate).then(() => {
            setTimeout(async () => {
              dispatch(setClientIsLoading(true))
              const appResUpd = await clientApi.applications.getApplication(appId)

              if (appResUpd.status === 200) {
                const appUpdStatusId = appResUpd.data.status.id

                if (appUpdStatusId === 11) {
                  // console.log(appUpdStatusId)
                }

                // Андеррайтинг
                if (appUpdStatusId === 2 && stepNext) {
                  const isFlowUpdate = await dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))
                  // @ts-ignore
                  if (isFlowUpdate) history.replace('/client/active-loans')
                }

                // Підписання договору
                if (((appUpdStatusId === 4) || (appUpdStatusId === 14)) && stepNext) {
                  dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))
                  window.location.reload()
                }
                // Reject
                if (appUpdStatusId === 9) {
                  history.replace('/client/active-loans')
                  if (appFormFlowData) {
                    dispatch(deleteUserFlow(appFormFlowData.id, false))
                  }
                  dispatch(setAppFormUserFlow(null))
                }
              }
              dispatch(setClientIsLoading(false))
            }, 4000)
          })
        }
      }
    } catch (err: any) {
      dispatch(setClientIsLoading(false))
      dispatch(
        setClientToastMessage({ type: 'error', message: getErrorMessage(err.responce.data) })
      )
    }
  }, [creditPolicy, settings])

  useEffect(() => {
    if (settings?.active && creditPolicy) {
      getCurrentStatusId(false)
    }
  }, [settings, creditPolicy])

  if (isLoading) {
    return <Box mt={5} textAlign="center">
      <AppPreloader />
      {t('client.applicationForm.bankCard.pleaseWait')}
    </Box>
  }

  if (!appFormFlowData || !creditPolicy || !currentUser) return <Typography>{t('client.applicationForm.bankCard.noData')}</Typography>

  const {
    flow_data: { id: appId, step }
  } = appFormFlowData

  const onSubmit = async (data: any) => {
    try {
      dispatch(setClientIsLoading(true))
      if (appFormFlowData?.flow_data.id) {
        dispatch(applicationRiskScoreRequest(appFormFlowData?.flow_data.id))
      }
      // 1 step - надсилаємо запит на `/client/${user_id}/bank_card/`
      const res = await clientApi.applicationForm.sendDataCard(
        currentUser.id,
        appId,
        creditPolicy.credit_type === 'Consumer finance' ? '4111111111111111' : data.card_number,
        creditPolicy.credit_type === 'Consumer finance' ? 'Test' : data.cardholder_name
      )

      if (res.status === 201) {
        await getCurrentStatusId(true)
      } else {
        dispatch(setClientIsLoading(false))
      }

      if (res.status === 400) {
        dispatch(
          setClientToastMessage({ type: 'error', message: getErrorMessage(res.data) })
        )
      }
    } catch (err: any) {
      dispatch(setClientIsLoading(false))
      dispatch(
        setClientToastMessage({ type: 'error', message: getErrorMessage(err.responce.data) })
      )
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {COUNTRY === 'Ukraine'
        ? <CardVerificationBlock />
        : <Box mb={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            style={{ marginBottom: '20px' }}
            variant="standard"
            label={COUNTRY === 'Ukraine' ? t('client.applicationForm.bankCard.bankCard') : t('client.applicationForm.bankCard.iban')}
            {...register('card_number', {
              required: t('formValidation.required'),
              minLength: {
                value: 16,
                message: t('formValidation.minLength', { n: 16 })
              },
              maxLength: {
                value: 16,
                message: t('formValidation.maxLength', { n: 16 })
              }
            })}
            type="number"
          />
          <FormErrorMessage errors={errors} name="card_number" />
          {/* <InputMask mask={'9999 9999 9999 9999'} maskChar={'-'} {...register('card_number')}> */}
          {/*  {() => ( */}
          {/*    <TextField */}
          {/*      style={{ marginBottom: '20px' }} */}
          {/*      variant="standard" */}
          {/*      label={t('client.applicationForm.bankCard.iban')} */}
          {/*      {...register('card_number')} */}
          {/*      type="text" */}
          {/*    /> */}
          {/*  )} */}
          {/* </InputMask> */}
          <TextField
            variant="standard"
            label={COUNTRY === 'Ukraine' ? t('client.applicationForm.bankCard.holder') : t('client.applicationForm.bankCard.taxNumber')}
            {...register('cardholder_name', {
              required: t('formValidation.required')
            })}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const string = event.currentTarget.value
              const arrayString = string?.split(' ')?.filter((value: string) => value !== '')

              if (arrayString?.length > 2) {
                event.currentTarget.value = arrayString?.join(' ')
              }
            }}
            type="text"
          />
          <FormErrorMessage errors={errors} name="cardholder_name" />
        </Box>
      }
      <Box>
        <ul>
          <h4>
            {t('client.applicationForm.bankCard.agreeAndCertify')}
          </h4>
          <li>
            {t('client.applicationForm.bankCard.consentToReceive')}
            {t('client.applicationForm.bankCard.representatives')}
          </li>
          <li>
            {t('client.applicationForm.bankCard.certify')}
          </li>
          <li>
            {t('client.applicationForm.bankCard.submitting')}
          </li>
          <li>
            {t('client.applicationForm.bankCard.understand')}
          </li>
        </ul>
      </Box>
      <Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2
        }}>
          <Button onClick={handlePrev} variant="contained">
            {t('client.applicationForm.bankCard.buttonPrev')}
          </Button>
          {COUNTRY !== 'Ukraine' && (
            <Button type="submit" variant="contained">
              {t('common.buttons.save')}
            </Button>
          )}
        </Box>
      </Box>
    </form>
  )
}

export default BankCardForm
