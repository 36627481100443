import { Paper } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { userFlowSelector } from 'redux/reducers/auth/authSelectors'
import { setAppFormFetchingStatus } from 'redux/reducers/client/allState/clientReducer'
import { appFormFetchingStatusSelector } from 'redux/reducers/client/allState/clientSelectors'
import { createGuarantorModelThunk } from 'redux/reducers/client/allState/clientThunks'

import GuarantorForm from './GuarantorForm'

const GarantorPage: React.FC = () => {
  const dispatch = useDispatch()

  const isFetching = useSelector(appFormFetchingStatusSelector)
  const { appFormFlowData } = useSelector(userFlowSelector)

  const submitHandler = async (data: any) => {
    if (!appFormFlowData) return

    data.application = appFormFlowData.flow_data.id
    data.date_of_issue = moment(data.date_of_issue).format('YYYY-MM-DD')
    dispatch(setAppFormFetchingStatus('load'))
    dispatch(createGuarantorModelThunk(data))
  }

  return (
    <Paper sx={{ mb: 2, p: 4, borderRadius: '30px' }} elevation={6}>
      <GuarantorForm isFetching={isFetching === 'load'} submitHandler={submitHandler} />
    </Paper>
  )
}

export default GarantorPage
